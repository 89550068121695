import { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom'; 
import { ApiContext } from '../../contexts/ApiContext';
import Spinner from '../spinner/Spinner';

export default function Search(){

    const { data, fetchProductById } = useContext(ApiContext);

    const navigate = useNavigate();

    //const [showButtonCode, setSohwButtonCode] = useState(true);
    const [showButtonBrand, setSohwButtonBrand] = useState(true);
    const [showButtonModel, setSohwButtonModel] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [filteredProducts, setFilteredProducts] = useState([]);

    // const showButtonCodeContent = () => {
    //     setSohwButtonCode(true);
    //     setSohwButtonBrand(false);
    //     setSohwButtonModel(false);
    // }

    const showButtonBrandContent = () => {
        //setSohwButtonCode(false);
        setSohwButtonBrand(true);
        setSohwButtonModel(false);
    }

    const showButtonModelContent = () => {
        //setSohwButtonCode(false);
        setSohwButtonBrand(false);
        setSohwButtonModel(true);
    }

    const handleInputChange = (event) => {
        const value = event.target.value;
        setSearchValue(value); // Actualiza el valor del input
    }

    const handleBrandChange = (event) => {
        const value = event.target.value;
        setSearchValue(value); // Actualiza el valor del select
        navigate(`/repuestos?brand=${value}`); // Redirige automáticamente cuando se selecciona una marca
    }

    useEffect(() => {
        if (showButtonModel && searchValue.trim().length >= 3) {
            const searchValueWithoutSpaces = searchValue.replace(/\s/g, '').toLowerCase();
            if (Array.isArray(data.getProductSearch) && data.getProductSearch.length > 0) {
                const filtered = data.getProductSearch.filter(product => 
                    product.nombre_producto.replace(/\s/g, '').toLowerCase().includes(searchValueWithoutSpaces)
                );
                setFilteredProducts(filtered);
            } else {
                setFilteredProducts([]);
            }
        } else {
            setFilteredProducts([]);
        }
    }, [searchValue, showButtonModel, data.getProductSearch]);

    const handleProductSelect = async (product) => {
        setFilteredProducts([]);
        await fetchProductById(product.id); 
        navigate(`/repuestos?brand=${product.brand_slug}&product=${product.slug}`);
    };

    if (data.loading) {
        return <Spinner />;
    }

    return(
        <StyleSearch>
            <div className="row">
                <div className="col-md-12 col-lg-7">
                    <div className="containerSearch">
                        <div className="containerButton ">
                            {/* <button className={showButtonCode ? 'activeButton buttonLeft' : 'deactivatedButton'} onClick={showButtonCodeContent}>Buscar por Código</button> */}
                            <button className={showButtonBrand ? 'activeButton buttonCenter' : 'deactivatedButton'} onClick={showButtonBrandContent}>Buscar por Marca</button>
                            <button className={showButtonModel ? 'activeButton buttonRight' : 'deactivatedButton buttonDesRight'} onClick={showButtonModelContent}>Buscar por Modelo</button>
                        </div>
                        <div className="containerInputSearch">
                            {showButtonModel ? (
                                <div className="dropdown" style={{width: '100%'}}>
                                    <input
                                        className='form-control'
                                        type="text"
                                        placeholder='Busque su repuesto'
                                        value={searchValue}
                                        onChange={handleInputChange}
                                    />
                                    {searchValue.length >= 3 && filteredProducts.length > 0 && (
                                        <ul className="list-group dropdown-menu show">
                                            {filteredProducts.map(product => (
                                                <li
                                                    key={product.id}
                                                    className="list-group-item list-group-item-action"
                                                    onClick={() => handleProductSelect(product)}
                                                >
                                                    {product.nombre_producto}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                           ) : (
                                <select className='form-control' value={searchValue} onChange={handleBrandChange}>
                                    <option value="">Seleccione una marca</option>
                                    {data.getBrands && data.getBrands.map(brand => (
                                        <option key={brand.id} value={brand.brand_slug}>{brand.nombre}</option>
                                    ))}
                                </select>
                            )}
                            <button className='btn btn-warning' ><i className="fa-solid fa-magnifying-glass"></i></button>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 col-lg-5">
                    <div className="containerImgSearch">
                        <img src="../../../assets/img/category/repuestos4.jpeg" alt="imagen de un repuesto" />
                    </div>
                </div>
            </div>                            
        </StyleSearch>
    )
}

const StyleSearch = styled.div `

    .containerSearch{
       background-color: #fff;
       height: 100%;
       border-radius: 0.3rem;
       border-bottom: solid 0.1rem darkgray;
       border-left: solid 0.1rem darkgray;
       border-right: solid 0.1rem darkgray;
       position: relative;
    }
    .dropdown-menu {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        z-index: 1000;
        display: none;
        float: left;
        min-width: 160px;
        padding: 5px 0;
        margin: 2px 0 0;
        font-size: 14px;
        color: #333;
        text-align: left;
        list-style: none;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
      }
      .dropdown-menu.show {
        display: block;
      }
    .containerButton{
        display: flex;
        justify-content: space-between;
        height: 5rem;
        color: #fff;
        gap: 0.4rem;
    }
    .activeButton {
        background-color: #FFF;
        color: #000;
        flex-grow: 1;
        border-top: solid 0.1rem darkgray;
        font-family: "Saira Condensed"
    }
    .buttonLeft{
        border-right: solid 0.1rem darkgray;
    }
    .buttonCenter{
        border-right: solid 0.1rem darkgray;
        //border-left: solid 0.1rem darkgray; //DESCOMENTAR CUANDO SE HABILITE EL BOTON POR CÓDIGO
    }
    .buttonRight{
        border-left: solid 0.1rem darkgray;
    }
    .deactivatedButton{
        background-color: var(--colorPrimario);
        color: #fff;
        flex-grow: 1;
        font-family: "Saira Condensed"
    }
    .buttonDesRight{
        border-right: solid 0.1rem var(--colorPrimario);
    }
    button {
        padding: 0rem 1rem;
    }
    .containerInputSearch{
        display: flex;
        margin: 5rem 0rem;
        padding: 0rem 1rem;
        flex-grow: 1;
        gap: 0.5rem;
    }
    .containerImgSearch {
        max-height: 30vh;
        overflow: hidden; /* Oculta el contenido que sobresale de la altura máxima */
        position: relative;
        margin-top: 1rem;
        @media (min-width: 992px){
            margin: 0px;
        }
    }

    img {
        position: relative;
        max-width: 100%; 
        top: 0vh;
        @media (min-width: 576px){
            top: -4vh;
        }
        @media (min-width: 768px){
            top: -10vh;
        }
        @media (min-width: 992px){
            top: -3vh;
            max-width: 150%;
        }
        @media (min-width: 1200px){
            max-width: 100%;
            top: 0vh;
        }
        @media (min-width: 1400px){
            top: -3vh;
        }
        @media (min-width: 1600px){
            top: -9vh;
        }
    }
`