import React, { useEffect, useState } from 'react';
import { getSolutionsToPoduct } from "../../service/ApiServicioTecnico";
import AccordionsGroup from '../accordion/AccordionsGroup';
import styled from 'styled-components';
import SubTitleSection from '../titleSection/SubTitleSection';
import { Link } from 'react-router-dom';

export default function RepairSelection({ productSlug }) {

    const [failure, setFailure] = useState(null);
    
    useEffect(() => {
        if (productSlug) {
            const fetchSolutions = async () => {
                const response = await getSolutionsToPoduct(productSlug.producto.id);
                setFailure(response);
            };
            fetchSolutions();
        }
    }, [productSlug]);

    if (!productSlug) {
        return <h3 style={{ textAlign: 'center', margin: '2rem 0rem' }}>Seleccione una Marca y un Producto para ver su ficha técnica</h3>;
    }

    const { producto } = productSlug;

    return (
        <StyledRepairSelection>
            <div className="containerRepair">
                <h2 className='titleProdut' style={{ margin: '0rem 0rem 2rem 0rem' }}>{producto.nombre_producto}</h2>
                <div className="row" style={{width: '100%'}}>
                    <div className="col-sm-12 col-md-12 col-lg-4" >
                        <div className="row">
                            <div className="col-md-6 col-lg-12">
                                <img
                                    src={`https://leiten.com.ar/storage/${producto.imagen_grande.replace(/^public\//, '')}`}
                                    style={{ padding: '1rem 0rem', width: '100%' }}
                                    alt={`Imagen de ${producto.nombre_producto}`}
                                />
                            </div>
                            <div className="col-md-6 col-lg-12">
                                <SubTitleSection title={'Archivos Relacionados'}/>
                                {productSlug.manuales.map( manual => (
                                    <div className='containerPdf' key={manual.id}>
                                        <img src="../../assets/img/pdf.png" alt="Logo de PDF" />
                                        <p>{manual.nombre_manual}</p>
                                        <Link   to={`https://leiten.com.ar/storage/${manual.archivo.replace(/^public\//, '')}`}
                                                target="_blank">
                                                    <i className="fa-solid fa-cloud-arrow-down"></i>
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-sm-12 col-md-12 col-lg-8">
                        <AccordionsGroup listFailure={failure}/>
                    </div>
                        
                </div>
               
            </div>
        </StyledRepairSelection>
    );
}

const StyledRepairSelection = styled.div`
    
    .containerRepair{
        //padding: 0rem 0rem 0rem 3rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 1.5rem;
        @media (min-width: 992px){
            margin-top: 0rem;
        }
    }
`