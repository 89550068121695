import { useState, useEffect } from "react";
import { getSolutiontToProductId, getSolutionsToPoduct } from "../../service/ApiServicioTecnico";
import styled from "styled-components";
import AccordionsGroup from "./AccordionsGroup";
import Spinner from "../spinner/Spinner";
import Modal from "../modal/Modal";

export default function Accordion(props) {
    const [solutions, setSolutions] = useState(null);
    const [subSolutions, setSubSolutions] = useState(null);
    const [loading, setLoading] = useState(false);

    const fetchSolutionsById = async () => {
        setLoading(true);
        try {
            const response = await getSolutiontToProductId(props.listAFail.type_id, props.listAFail.solution_id);
                setSolutions(response.data);
            } catch (error) {
                console.error('Error fetching solutions:', error);
            } finally {
                setLoading(false);
            }
        };

    useEffect(() => {
        if (props.listAFail.solution_id) {
        const fetchSolutions2 = async () => {
            const response = await getSolutionsToPoduct(props.listAFail.type_id, props.idFail);
            setSubSolutions(response.subsoluciones);
        };
        fetchSolutions2();
        }
    }, [props.listAFail.solution_id]);

    const handleAccordionClick = () => {
        fetchSolutionsById();
    };

    return (
        <StyledAccordion>
            <div className="accordion-item accordion" id={'heading' + props.listAFail.id}>
                <h3 className="accordion-header">
                    <button className="accordion-button collapsed"
                            type="button"
                            //style={{padding: '1rem 0rem'}}
                            data-bs-toggle="collapse"
                            data-bs-target={"#flush-collapseOne" + props.listAFail.id}
                            aria-expanded="false"
                            aria-controls={"flush-collapseOne" + props.listAFail.id}
                            onClick={handleAccordionClick}>
                            {props.listAFail.item_solution ? props.listAFail.item_solution.name : 'Consultar por estado de solución'}
                    </button>
                </h3>
                <div    id={"flush-collapseOne" + props.listAFail.id} className="accordion-collapse collapse"
                        aria-labelledby={"flush-headingOne" + props.listAFail.id}
                        data-bs-parent={"#" + props.parentAccordionId}>
                    
                    <div className="accordion-body">
                        {loading ? (
                            <>
                                <p>Cargando soluciones...</p>
                                <Spinner/>
                            </>
                        ) : (
                            solutions ? (
                                <>
                                    {/* <p>ID de Falla: {solutions.solucion.id}</p> */}
                                    { solutions.documentos.length > 0 ? (
                                        <>
                                            <div>
                                                {solutions.documentos.map((documento, index) => (
                                                    documento.state === 'approved' ? (
                                                        <div key={documento.id} style={{margin: '0.8rem 0rem'}}>
                                                            {/* <p>Tipo de Documento: {documento.type}</p> */}
                                                            <Modal id={`modal-${props.listAFail.id}-${index}`} data={documento} />
                                                        </div>
                                                    ) : null 
                                                ))}
                                            </div>
                                        </>
                                    ) : (
                                        null
                                    )}
                                    <AccordionsGroup listFailure={subSolutions} accordionGroupId={"subAccordionGroup" + props.listAFail.id} />
                                </>
                            ) : (
                                <p>No hay soluciones disponibles</p>
                            )
                        )}
                    </div>
                </div>
            </div>
        </StyledAccordion>
    );
}


const StyledAccordion = styled.div`
    .accordion-header {
        display: flex;
        align-items: center;
  }

  .accordion-button {
        padding: 1rem;
        margin: 0;
        flex-grow: 1;
  }
`