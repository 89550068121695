import { Link } from 'react-router-dom';

export default function ManualsSelection({ productSlug }) {

    if (!productSlug) {
        return <h3 style={{textAlign: 'center', margin: '2rem 0rem'}}> Seleccione una Marca y un Producto para ver sus Manuales</h3>;
    }

    const { producto } = productSlug;

    return(
        <>
            <h2 className="titleProdut" style={{margin: '2rem 0rem'}}>{producto.nombre_producto}</h2>
            <div className="row">
                <div className="col-sm-12 col-md-5">
                    <img    src={`https://leiten.com.ar/${producto.imagen_grande.replace('public/', 'storage/')}`} 
                            className="card-img-top" 
                            style={{padding: '1rem'}}
                            alt={`Imagen de ${producto.nombre_producto}`} 
                            />
                </div>
                <div className="col-sm-12 col-md-7">
                    {productSlug.manuales.map( manual => (
                        <div className='containerPdf' key={manual.id}>
                            <img src="../../assets/img/pdf.png" alt="Logo de PDF" />
                            <p>{manual.nombre_manual}</p>
                            <Link   to={`https://leiten.com.ar/storage/${manual.archivo.replace(/^public\//, '')}`}
                                    target="_blank">
                                        <i className="fa-solid fa-cloud-arrow-down"></i>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}