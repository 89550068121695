import { createContext, useState, useEffect, useCallback } from "react";
import {getBrands, getProductsByBrand, getProductById, getProductPrice, getProductByCode, getKitMaintenance, getProductSearch} from '../service/ApiLeiten';

export const ApiContext = createContext();

export const ApiData = ({ children }) => {

    const initialState = {
        getBrands: null,
        getProductsByBrand: null,
        getProductById: null,
        getProductByCode: null,
        getProductPrice: null,
        getKitMaintenance: null,
        getProductSearch: null,
        loading: true,
    };

    const [data, setData] = useState(() => {
        const savedData = localStorage.getItem('apiData');
        return savedData ? JSON.parse(savedData) : initialState;
    });

    useEffect(() => {
        const fetchBrands = async () => {
            try {
                const response = await getBrands();
                setData(prevState => {
                    const newState = { ...prevState, getBrands: response.data, loading: false };
                    localStorage.setItem('apiData', JSON.stringify(newState));
                    return newState;
                });
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };
        fetchBrands();
    }, []);

    const fetchProductsByBrand = async (slugbrand) => { 
        try {
            const response = await getProductsByBrand(slugbrand);
            setData(prevState => ({ ...prevState, getProductsByBrand: response.data }));
        } catch (error) {
            console.error('Error fetching products by brand:', error);
        }
    };


    const fetchProductById = async (id) => {
        try {
            const response = await getProductById(id);
            setData(prevState => ({ ...prevState, getProductById: response.data }));
        } catch (error) {
            console.error('Error fetching product by id:', error);
        }
    };
    
    const ferchProductPrice = async (id) => {
        try {
            const response = await getProductPrice(id);
            setData(prevState => ({ ...prevState, getProductPrice: response.data }));
        } catch (error) {
            console.error('Error fetching product by id:', error);
        }
    };

    const fetchProductByCode = async (code) => {
        try {
            const response = await getProductByCode(code);
            setData(prevState => ({ ...prevState, getProductByCode: response.data }));
        } catch (error) {
            console.error('Error fetching product by code:', error);
        }
    };

    const fetchKitMaintenance = async () => {
        try {
            const response = await getKitMaintenance();
            setData(prevState => ({ ...prevState, getKitMaintenance: response.data }));
        } catch (error) {
            console.error('Error fetching kit Maintenance:', error);
        }
    };

    const emptyAll = useCallback(async ()=> {
        setData(prevState => ({
            ...prevState,
            getProductsByBrand: null,
            getProductById: null,
           // getProductByCode: null,
        }));
    }, []);
    
    useEffect(() => {
    const fetchProductSearch = async () => {
        try {
            const response = await getProductSearch();
            setData(prevState => ({ ...prevState, getProductSearch: response }));
        } catch (error) {
            console.error('Error searching products:', error);
        }
    };
    fetchProductSearch();
    }, []);

    return (
        <ApiContext.Provider value={{data, fetchProductsByBrand, fetchProductById, ferchProductPrice, fetchProductByCode, fetchKitMaintenance, emptyAll}}>
            {children}
        </ApiContext.Provider>
    );
};