import React from 'react';
import styled from 'styled-components';
import ProductCard from '../card/ProductCard';

export default function AccessorySelection({ productSlug }) {

    if (!productSlug) {
        return <h3 style={{textAlign: 'center', margin: '2rem 0rem'}}> Seleccione una Marca y un Producto para ver sus Accesorios</h3>;
    }

    const { producto } = productSlug;

    return(
        <StyledAccessorySelection>
            <div className="containerStyledAccessory">
                <h2 className="titleProdut" style={{margin: '2rem 0rem'}}>{producto.nombre_producto}</h2>
                <ProductCard listSpartPart={producto} type="accessory"/>
            </div>
        </StyledAccessorySelection>
    )
}

const StyledAccessorySelection = styled.div`
    .containerStyledAccessory{
        display: flex;
        flex-direction: column;
        align-items: center;
        @media (min-width: 992px){
            padding: 0rem 0rem 0rem 3rem;
        }
    }
`