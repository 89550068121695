import React, { useEffect, useContext } from 'react';
import { useSearchParams } from "react-router-dom";
import { ApiContext } from '../../contexts/ApiContext';
import Filter from '../../component/filter/Filter';
import Banner from '../../component/banner/Banner';
import TitleSection from '../../component/titleSection/TitleSection';
import CommonSpartPartSelecction from '../../component/commonSpareParts/CommonSparePartsSelecction';
import SearchInput from '../../component/search/SearchInput';

export default function SpartPartView() {

    const {data, fetchProductById, emptyAll} = useContext(ApiContext);

    const [searchParams] = useSearchParams();
    const brandParams = searchParams.get('brand');
    const productParams = searchParams.get('product');

    useEffect(() => {
        window.scrollTo(0, 0);
        return () => {
            emptyAll();
        };
    }, []);

    const handleProductSelect = async (productId) => {
        try {
            await fetchProductById(productId);
        } catch (error) {
            console.error('Error fetching product:', error);
        }
    };

    return (
        <>
            <Banner banner={banner}/>
            <div className="containerPortal paddingSection">
            <TitleSection title={'Encontra todos los Kit para tu Equipo'} />

                <div className="row">
                    <div className="col-sm-12 col-md-3 col-xl-2">
                        <div style={{ paddingBottom: '2rem' }} >
                            <h5>Ingrese o Seleccione su Modelo</h5>
                            <SearchInput />
                        </div>
                        <Filter slugBrandReceived={brandParams} slugProductReceived={productParams} onProductSelect={handleProductSelect}/>
                    </div>
                    <div className="col-sm-12 col-md-9 col-xl-10">
                        <CommonSpartPartSelecction productSlug={data.getProductById}/>
                    </div>
                </div>
            </div>
        </>
    );
}

const banner = 
[
    {id: 1, title: 'Kits de Mantenimiento', img: '../../../assets/img/banner/bannerKit.jpg', alt: 'Imagen Banner' },
];
