import { useState, useEffect, useContext, useRef } from 'react';
import styled from 'styled-components';
import Spinner from '../spinner/Spinner';
import { ApiContext } from '../../contexts/ApiContext';

export default function Filter({ slugBrandReceived, slugProductReceived, onProductSelect }) {
    const { data, fetchProductsByBrand } = useContext(ApiContext);
    const [changesParams, setChangesParams] = useState(slugBrandReceived);
    const [loadingProducts, setLoadingProducts] = useState(false);
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const brandContainerRef = useRef(null);

    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    useEffect(() => {
        const fetchBrands = async () => {
            try {
                const brands = data.getBrands;

                if (slugBrandReceived) {
                    const brand = brands.find(brand => brand.brand_slug === slugBrandReceived);
                    if (brand) {
                        setSelectedBrand({ id: brand.id, slug: brand.nombre });
                        
                        if (slugProductReceived) {
                            const productBrand = await data.getProductsByBrand(brand.brand_slug);
                            const product = productBrand.find(product => product.slug === slugProductReceived);

                            if (product) {
                                setSelectedProduct(product.id);
                                onProductSelect(product.id);
                            }
                        }
                    }
                }
            } catch (error) {
                console.error('Error fetching brands:', error);
            }
        };
        fetchBrands();
    }, [slugBrandReceived]);

    useEffect(() => {
        const fetchSelectProductsByBrand = async () => {
            setLoadingProducts(true);
            try {
                if (selectedBrand) {
                    await fetchProductsByBrand(selectedBrand.slug);
                }
            } catch (error) {
                console.error('Error fetching products:', error);
            } finally {
                setLoadingProducts(false);
            }
        };

        fetchSelectProductsByBrand();
    }, [selectedBrand]);

    useEffect(() => {
        if (selectedBrand && brandContainerRef.current) {
            const selectedBrandElement = brandContainerRef.current.querySelector(`input[value="${selectedBrand.slug}"]`);
            if (selectedBrandElement) {
                selectedBrandElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
            }
        }
    }, [selectedBrand]);

    const handleRadioChangeBrand = (brandId, brandSlug) => {
        setChangesParams(brandSlug);
        setSelectedBrand({ id: brandId, slug: brandSlug });
        setSelectedProduct(null); // Clear selected product when brand changes
    };

    const handleRadioChangeProduct = (productId) => {
        setSelectedProduct(productId);
        onProductSelect(productId); // Call the callback with the selected product ID
    };

    return (
        <>
            <h5>Seleccione una Marca</h5>
            <StyledRow className="row customFilter">
                <div className="col-12">
                    <ul>
                        <StyleFilterInput ref={brandContainerRef}>
                            {data.getBrands && data.getBrands.map(brand => (
                                <li key={brand.id}>
                                    <label className='labelFilter'>
                                        <input className='form-input inputFilter'
                                               type="radio"
                                               name="brand"
                                               value={brand.brand_slug}
                                               checked={selectedBrand?.id === brand.id}
                                               onChange={() => handleRadioChangeBrand(brand.id, brand.nombre)}
                                        />
                                        {brand.nombre}
                                    </label>
                                </li>
                            ))}
                        </StyleFilterInput>
                    </ul>
                </div>
            </StyledRow>
            
           {loadingProducts ? ( <Spinner />
                
                ) : (
                    data.getProductsByBrand && (
                    <StyleFilterInput>
                        <h5 className="product-heading">Seleccione un Producto</h5>
                        <StyledRow className="row productFilter">
                            <div className="col-12">
                                <ul>
                                    {data.getProductsByBrand.map(product => (
                                        <li key={product.id}>
                                            <label className='labelFilter'>
                                                <input className='form-input inputFilter text-success'
                                                    type="radio"
                                                    name="product"
                                                    checked={selectedProduct === product.id}
                                                    onChange={() => handleRadioChangeProduct(product.id)}
                                                />
                                                {product.nombre_producto}
                                            </label>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </StyledRow>
                    </StyleFilterInput>
                )
            )}
        </>
    );
}

const StyledRow = styled.div`
    &.customFilter {
        max-height: 250px;
        overflow-y: auto;
        max-width: 70%;
        margin: 0 auto;
        @media (min-width: 992px){
            min-width: 100%;
            margin: 0;
        }
    }
    
    &.productFilter {
        height: 250px;
        overflow-y: auto;
        max-width: 70%;
        margin: 0 auto;
          @media (min-width: 992px){
            min-width: 100%;
            margin: 0;
        }
    }
`;

const StyleFilterInput = styled.div`
    .labelFilter {
        display: flex;
    }
    .inputFilter {
        margin-right: 0.3rem;
    }
    .product-heading {

        padding-bottom: 0.6rem;
        margin-top: 2rem !important;
    }
`;
