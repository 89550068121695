import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Styled from 'styled-components';
import BurguerButton from "../../component/buttons/BurguerButton";

export default function Header() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [clicked, setClicked] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleClick = () => {
        setClicked(!clicked);
        if (!clicked) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    };

    const handleMenuItemClick = () => {
        setClicked(false); // Cierra el menú burger cuando se hace clic en un botón del menú.
        document.body.style.overflow = "auto"; // Asegúrate de restaurar el desplazamiento del cuerpo.
    };

    return (
        <StyledHeader>
            <div className="containerPortal">
                {windowWidth >= 992 ? (
                    <div className="row" style={{ display: 'flex', alignItems: 'center', height: 'var(--alturaBarra)' }}>
                        <div className="col">
                            <Link to='/'><img src="../../../assets/img/logo.svg" alt='Logo Plataforma Técnica' /></Link>
                        </div>
                        <div className="col" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }} >
                            <ul className='contenMenu'>
                                <li className='itemMenu'><Link className="linkDesktop" to='/repuestos'><i className="fa-solid fa-gear"></i>Repuestos</Link></li>
                                <li className='itemMenu'><Link className="linkDesktop" to='/accesorios'><i className="fa-solid fa-plug"></i>Accesorios</Link></li>
                                <li className='itemMenu'><Link className="linkDesktop" to='/kitMantenimientos'><i className="fa-solid fa-gears"></i>Kit de Mantenimiento</Link></li>
                                <li className='itemMenu'><Link className="linkDesktop" to='/manuales'><i className="fa-solid fa-book-open"></i>Manuales</Link></li>
                                <li className='itemMenu'><Link className="linkDesktop" to='/reparacion'><i className="fa-solid fa-wrench"></i>Reparar tu Equipo</Link></li>
                                <li className='itemMenu'><Link className="linkDesktop" to='/visitaObra'><i className="fa-solid fa-route"></i>Visita a Obra</Link></li>
                                {/* <i className="fa-solid fa-gear"></i> repuestos
                                <i className="fa-solid fa-book-open"></i> manuales */}
                                {/* <i className="fa-solid fa-truck"></i> visita a obra */}
                                {/* <i className="fa-solid fa-route"></i>visita a obra */}
                            </ul>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="containerBurger" style={{ height: 'var(--alturaBarraMobile)' }}>
                            <Link to='/'><img src="../../../assets/img/logo.svg" alt='Logo Plataforma Técnica' /></Link>
                            <BurguerButton clicked={clicked} handleClick={handleClick} />
                        </div>
                        <div className={`burgerMenu ${clicked ? 'open' : ''}`}>
                            <ul>
                                <li><Link className='itemMenuBurger' to='/' onClick={handleMenuItemClick}><i className="fa-solid fa-house"></i> Inicio</Link></li>
                                <li><Link className='itemMenuBurger' to='/repuestos' onClick={handleMenuItemClick}><i className="fa-solid fa-gear"></i> Repuestos</Link></li>
                                <li><Link className='itemMenuBurger' to='/accesorios' onClick={handleMenuItemClick}><i className="fa-solid fa-plug"></i> Accesorios</Link></li>
                                <li><Link className='itemMenuBurger' to='/kitMantenimientos' onClick={handleMenuItemClick}><i className="fa-solid fa-gears"></i> Kit de Mantenimiento</Link></li>
                                <li><Link className='itemMenuBurger' to='/manuales' onClick={handleMenuItemClick}><i className="fa-solid fa-book-open"></i> Manuales</Link></li>
                                <li><Link className='itemMenuBurger' to='/reparacion' onClick={handleMenuItemClick}><i className="fa-solid fa-wrench"></i> Reparar tu Equipo</Link></li>
                                <li><Link className='itemMenuBurger' to='/visitaObra' onClick={handleMenuItemClick}><i className="fa-solid fa-route"></i> Visita a Obra</Link></li>
                            </ul>
                        </div>
                    </>
                )}
            </div>
        </StyledHeader>
    );
}

const StyledHeader = Styled.div`
    img{
        width: 180px;
        @media (min-width: 992px){
            width: 250px;
        }
    }
    .contenMenu{
        display: flex;
        justify-content: flex-end;
        margin-bottom: 0px !important;
    }
    .itemMenu{
        font-weight: 600; //antes del cambio de martin
        font-size: 1.2rem; //antes del cambio de martin
        padding: 0rem 1rem;
        border-bottom: transparent solid 2px;
    }
    .itemMenu:hover{
        padding: 0rem 1rem;
        border-bottom: var(--colorPrimario) solid 2px;
    }
    .containerBurger{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .burgerMenu{
        height: 0;
        overflow: hidden;
        transition: height 0.5s ease;
    }
    .burgerMenu.open{
        height: auto; //altura del fondo menu burger abierto
    }
    li{
        padding: 1rem 0rem;
    }
    .itemMenuBurger{
        padding: 0.5rem 0rem;
        border-bottom: transparent solid 2px;
    }
    .itemMenuBurger:hover{
        padding: 0.5rem 0rem;
        border-bottom: var(--colorPrimario) solid 2px;
    }
    i{
        padding: 2px;
    }
    .linkDesktop {
        display: flex;
        flex-direction: column;
        text-align: center;
    }
`;
