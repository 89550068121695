export async function getSolutionsToPoduct(idProduct, idSolution = '' ) {

    try {
        const response = await fetch(`${process.env.REACT_APP_TS_URL}/api/product/${idProduct}/solutions/${idSolution}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': process.env.REACT_APP_TS_ACCESS
            }
        });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json()
            return data.data ? data.data : data

    } catch (error) {
        console.error('Error:', error);
        return 'error';
    }
}

export async function getSolutiontToProductId (idProduct, idSolution){
    try {
        const response = await fetch(`${process.env.REACT_APP_TS_URL}/api/product/${idProduct}/solutions/${idSolution}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': process.env.REACT_APP_TS_ACCESS
            }
        });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return await response.json();

    } catch (error) {
        console.error('Error:', error);
        return 'error';
    }
}