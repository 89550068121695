import styled from 'styled-components';

const Modal2 = ({ id, data, show, handleCloseClick }) => {
    if (!show) {
        return null;
    }

    return (
        <StyledModal>
            <div className="modal fade" id="exampleModal">
            <div className="modal-dialog">
                <div className="modal-content">
                <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">Modal title</h1>
                    <button type="button" className="btn-close" onClick={handleCloseClick}></button>
                </div>
                <div className="modal-body">
                    ...
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" className="btn btn-primary">Save changes</button>
                </div>
                </div>
            </div>
            </div>
        </StyledModal>
    );
};

export default Modal2;

const StyledModal = styled.div`
    /* Estilos para el modal */
`;
